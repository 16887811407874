import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class Securefeaof extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner secureof pt-100">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Top Security Features</span> of Our Crypto Exchange Development Service</h2>
          <p className="sub-heading-bold">At Coinsclone, we prioritize security and conjoin high-end security protocols to develop a crypto exchange.</p>
          <div className="row table-content mw1030">
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 left-side" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/security-features.png"
                alt="Security Features of our Crypto Exchange Software Development"
                width={587}
                height={451}
                imgClassName='labtop'
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <ul>
                <li>HTTPs authentication</li>
                <li>Jail login</li>
                <li>End-to-End encryption</li>
                <li>Data encryption</li>
                <li>Two-factor authentication</li>
                <li>SQL injection prevention</li>
                <li>Cross-Site Request Forgery (CSRF) Protection</li>
                <li>Server-Side Request Forgery (SSRF) Protection</li>
                <li>Anti-Denial Of Service (DoS)</li>
                <li>Anti-Distributed Denial Of Service(DDoS)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Securefeaof