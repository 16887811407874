import React from 'react'



class Topfeaofour extends React.Component { 
  
  
  render() {

    return (
      <section className="icon secureof pt-100 padremove">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Top Features</span> of Our Cryptocurrency Exchange Software Development</h2>
          <p className="sub-heading-bold">Our ultra-modern cryptocurrency exchange platform development comes pre-loaded with industry-leading features.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/robust-trading-engine.png" alt="Robust trading engine" />
                Robust trading engine
                  </div>
                <p className="pharagraph">This functionality will assist you in increasing user engagement and guarantee that cryptocurrency transactions are completed promptly.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/aml-verification.png" alt='KYC/AML Verification' />
                KYC/AML Verification
                </div>
                <p className="pharagraph">Build your own cryptocurrency exchange by obtaining the user's photographs, Gmail account, bank accounts, legal papers, and other government-issued identification, you can confirm their identity. When suspect users' KYC checks fail, you can also ignore them.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/advanced-chart-view.png" alt='Advanced Chart view' />
                Advanced Chart view
                </div>
                <p className="pharagraph">Setting up a cryptocurrency exchange with an advanced chart view is an added advantage. In a chart view, your viewers can see the historical and current prices. Additionally, it will assist them in doing a forecasting analysis of the future price of all cryptocurrencies.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/admin-console.png" alt='Admin Console' />
                Admin Console
                </div>
                <p className="pharagraph">The intuitive admin dashboard assists the platform's administrator in properly managing finances, user information, and other imperative features for running a crypto exchange.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/user-dashboard.png" alt='User Dashboard' />
                User dashboard
                </div>
                <p className="pharagraph">The user dashboard we designed to make a cryptocurrency exchange is equipped with all the necessary tools to help your users trade cryptocurrency, check trading charts and cryptocurrency prices, make deposits, and transfer funds.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/cryptocurrency-wallet-integration.png" alt='Wallet Integration' />
                Wallet Integration
                </div>
                <p className="pharagraph">The cryptocurrency wallet that we enable to build a cryptocurrency exchange platform allows your customers to securely store, receive, and transfer cryptocurrencies from one wallet address to another. Furthermore, the private and public keys will be activated for secure and quick transactions.</p>
              </div>
            </div>
          </div>
          <div className="row "> 
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/crypto-payment-gateway-integration.png" alt='Payment Gateway Integration' />
                Payment Gateway Integration
                </div>
                <p className="pharagraph mb-0">Building your platform by Integrating a payment gateway will allow your users to conduct transactions more swiftly. As a result, it will be more convenient for your users to withdraw or deposit funds.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/order-book.png" alt='Order book' />
                Order book
                </div>
                <p className="pharagraph mb-0">Start your own cryptocurrency exchange with this feature that enables your users to trade cryptocurrencies instantly by accessing the order book, which displays all buy and sell orders for the cryptocurrency. Building your own crypto exchange with this exceptional feature would make it much easier to attract worldwide traders and investors.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/integrated-ieo-module.png" alt='Integrated IEO module' />
                Integrated IEO module
                </div>
                <p className="pharagraph mb-0">We enable an IEO launchpad in the platform to assist token owners in conducting sales for newly released crypto tokens. You may make passive money as a crypto exchange owner by collecting fees for hosting the token sale and distributing the tokens.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/liquidity-api.png" alt='Liquidity API' />
                Liquidity API
                </div>
                <p className="pharagraph mb-0">It is a crucial component of our custom-built cryptocurrency exchange development. Your users could conduct efficient transactions that are unaffected by market price swings.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 m-auto d-table">Free Trial</a>
          </div>
      </section>
    )
  }
}

export default Topfeaofour