import React from 'react';

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/Cryptoexchange/Banner"
import Pickcrypto from "../components/Cryptoexchange/Pickcrypto"
import Topfeaofour from "../components/Cryptoexchange/Topfeaofour"
import Securefeaof from "../components/Cryptoexchange/Securefeaof"
import Businessperk from "../components/Cryptoexchange/Businessperks"
import Lucrative from "../components/Cryptoexchange/Lucrative"
import Addonmode from "../components/Cryptoexchange/Addonmode"
import TechStack from "../components/Cryptoexchange/TechStack"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import FaqSection from '../components/Cryptoexchange/FaqSection';
import IdealCrypto from '../components/Cryptoexchange/Idealcrypto';
import DevMethod from '../components/Cryptoexchange/Devmethods';
import VariousBlockchain from '../components/Cryptoexchange/Variousblockchain';
import DevApproach from '../components/Cryptoexchange/DevApproach';
import BookFree from '../components/Cryptoexchange/BookFree';
import CaseStudy from '../components/Cryptoexchange/CaseStudy';


const Cryptoexchange = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-exchange-development-company/" />
        <title>Crypto Exchange Development Company</title>
        <meta name="description" content="Coinsclone is a leading cryptocurrency exchange development company that provides various crypto exchange software development services at a best price with industry-leading features." />
        <meta name="keywords" content="Cryptocurrency exchange development company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Crypto Exchange Development Company" />
        <meta property="og:description" content="Coinsclone is a leading cryptocurrency exchange development company that provides various crypto exchange software development services at a best price with industry-leading features." />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-exchange-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/cryptocurrency-exchange-development-company.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a leading cryptocurrency exchange development company that provides various crypto exchange software development services at a best price with industry-leading features." />
        <meta name="twitter:title" content="Crypto Exchange Development Company" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/cryptocurrency-exchange-development-company.png" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Cryptocurrency Exchange Development Company</span>
        </div>
      </div>
      <IdealCrypto />
      <CaseStudy />
      <Pickcrypto />
      <DevMethod />
      <Topfeaofour />
      <Securefeaof />
      <Businessperk />
      <Lucrative />
      <Addonmode />
      <VariousBlockchain />
      <TechStack />
      <DevApproach />
      <BookFree />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Cryptoexchange
