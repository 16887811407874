import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class Businessperk extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner secureof pt-100">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Business Perks</span> of Choosing Our Crypto Exchange Development Service</h2>
          <p className="sub-heading-bold">Reap numerous business benefits by acquiring a peerless crypto exchange development service from Coinsclone to build a crypto exchange.</p>
          <div className="row table-content mw1030">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <ul>
                <li>Reliable software</li>
                <li>100% optimizable</li>
                <li>Cost-effective</li>
                <li>Glitch-free and secure</li>
                <li>Meticulously tested software</li>
                <li>Instant deployment</li>
                <li>No need for technical expertise</li>
                <li>Generate High ROI shortly</li>
                <li>Boost brand visibility easily</li>
                <li>Highly responsive without technical flaws</li>
              </ul>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-12 left-side" >
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/business-perks.png"
              alt="Business Perks of Choosing Our Cryptocurrency Exchange Software Development Image"
              width={593}
              height={451}
              imgClassName='labtop'
            />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Businessperk